import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { changeLogo, contactLogo, logoutLogo, shieldLogo, techHelp, termsLogo } from "../../customisableuserprofiles/src/assets";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  showStudy: boolean;
  showButtonChats?: boolean;
  showButtonNotification?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  detail: any
  open: boolean,
  passwordDialogOpen: boolean;
  editProfile: boolean;
  expanded: any;
  termsDialogOpen: boolean;
  privacyDialogOpen: boolean;
  techBarOpen: boolean;
  contactBarOpen: boolean;
  requestAdmin: boolean;
  oldPassword: any,
  newPassword: any,
  newConfirmPassword: any,
  oldPasswordError: any,
  newPasswordError: any,
  newConfirmPasswordError: any,
  newPasswordSuccess: boolean;
  oldEmailAddress: any,
  newEmailAddress: any,
  newConfirmEmailAddress: any,
  oldEmailAddressError: any,
  newEmailAddressError: any,
  newConfirmEmailAddressError: any,
  addressLine1: string,
  addressLine2: string,
  addressLine3: string,
  phoneNumber: any,
  phoneNumberError: any,
  fullName: any,
  fullNameError: any
  userEmail: any,
  userEmailError: any,
  contactFullName: any,
  contactFullNameError: any,
  contactUserEmail: any,
  contactUserEmailError: any,
  selectedReason: any,
  selectError: any,
  contactUserMessage: any,
  userDetails: any,
  termsAndConditions: any,
  privacyPolicy: any,
  technicalHelpData: any,
  showOldPassword:boolean,
  showNewPassword:boolean,
  showNewConfirmPassword:boolean,
  stdInput:any,
  studyData: any,
  searchQuery:any,
  selectedStudy:any,
  studyDrawerOpen:boolean,
  showToast:boolean,
  profilePicture: File | string,
  imageToSent:File | null,
  profilePicError : boolean,
  currentStudy : any,
  logoutFlag : boolean,
  completeAddress : string,
  profilePictureEdit: File | string,
  languageLogoDrawer : boolean,
  selectedLanguage : string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SideNavbarController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserProfileApiCallId: string = "";
  getTermsandConditionApiCallId: string = "";
  getPrivacyPolicyApiCallId: string = "";
  getFaqApiCallId: string = "";
  contactFormApiCallId: string = "";
  editUserProfileApiCallId: string = "";
  changePasswordApiCallId: string = "";
  changeEmailApiCallId: string = "";
  getStudyIndexApiCallId: string = "";
  popupRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      
      open: false,
      passwordDialogOpen: false,
      editProfile: false,
      expanded: false,
      termsDialogOpen: false,
      privacyDialogOpen: false,
      techBarOpen: false,
      contactBarOpen: false,
      requestAdmin: false,
      oldPassword: "",
      newPassword: "",
      newConfirmPassword: "",
      oldPasswordError: "",
      newPasswordError: "",
      newConfirmPasswordError: "",
      newPasswordSuccess: false,
      oldEmailAddress: '',
      newEmailAddress: '',
      newConfirmEmailAddress: '',
      oldEmailAddressError: '',
      newEmailAddressError: '',
      newConfirmEmailAddressError: '',
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      phoneNumber: null,
      phoneNumberError: '',
      fullName: '',
      fullNameError: '',
      userEmail: '',
      userEmailError: '',
      contactFullName: '',
      contactFullNameError: '',
      contactUserEmail: "",
      contactUserEmailError: "",
      selectedReason: '',
      selectError: '',
      contactUserMessage: '',
      userDetails: null,
      termsAndConditions: null,
      privacyPolicy: null,
      technicalHelpData: null,
      showOldPassword:false,
      showNewPassword:false,
      showNewConfirmPassword:false,
      stdInput:'',
      studyDrawerOpen:false,
      selectedStudy:null,
      searchQuery:'',
      studyData : null,
      detail: [{
        dName: 'Change Password',
        id: "change-password",
        dImg: changeLogo,
      },
      {
        dName: 'Terms and Conditions',
        id: "terms-condt",
        dImg: termsLogo
      },
      {
        dName: 'Privacy Policies',
        id: "privacy-policy",
        dImg: shieldLogo
      },
      {
        dName: 'Technical Help',
        id: "tech-help",
        dImg: techHelp
      },
      {
        dName: 'Contact Us',
        id: "contact-us",
        dImg: contactLogo
      },
      {
        dName: 'Logout',
        id: "logout",
        dImg: logoutLogo
      }
      ],
      showToast:false,
      profilePicture: "",
      imageToSent:null,
      profilePicError : false,
      currentStudy : typeof window !== 'undefined' && window.localStorage.getItem("studyName"),
      logoutFlag : false,
      completeAddress : '',
      profilePictureEdit : ' ',
      languageLogoDrawer : false,
      selectedLanguage : ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.popupRef = createRef();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const handleRestApiResponse = (message: Message) => {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (apiRequestCallId != null && responseJson !== undefined) {
          switch (apiRequestCallId) {
            case this.getUserProfileApiCallId:
              if(responseJson.errors){
                this.navigateToLogin();
                break;
              }
              this.setState({ 
                userDetails: responseJson.data.attributes,
                fullName: responseJson.data.attributes.full_name,
                userEmail: responseJson.data.attributes.email,
                phoneNumber: responseJson.data.attributes.full_phone_number,
                addressLine1 : responseJson.data.attributes.address_line1,
                addressLine2 : responseJson.data.attributes.address_line2,
                addressLine3 : responseJson.data.attributes.address_line3,
                profilePicture:responseJson?.data?.attributes?.profile_image,
                profilePictureEdit : this.state.profilePicture,
                completeAddress: this.constructCompleteAddress(
                  responseJson.data.attributes.address_line1,
                  responseJson.data.attributes.address_line2,
                  responseJson.data.attributes.address_line3
                )
              });
              
              break;
            case this.getTermsandConditionApiCallId:
              this.setState({ termsAndConditions: responseJson.data.description });
              break;
            case this.getPrivacyPolicyApiCallId:
              this.setState({ privacyPolicy: responseJson.data.description });
              break;
            case this.getFaqApiCallId:
              this.setState({ technicalHelpData: responseJson.data });
              break;
            case this.editUserProfileApiCallId:
              this.setState({ 
                userDetails: responseJson.data.attributes,
                userEmail: responseJson.data.attributes.email,
                fullName: responseJson.data.attributes.full_name,
                phoneNumber: responseJson.data.attributes.full_phone_number,
                addressLine1 : responseJson.data.attributes.address_line1,
                addressLine3 : responseJson.data.attributes.address_line2,
                profilePicture:responseJson?.data?.attributes?.profile_image,
                addressLine2 : responseJson.data.attributes.address_line3,
                completeAddress: this.constructCompleteAddress(
                  responseJson.data.attributes.address_line1,
                  responseJson.data.attributes.address_line2,
                  responseJson.data.attributes.address_line3
                )
              });
              break;
            case this.getStudyIndexApiCallId:
              
              this.setState({ studyData : responseJson.data});
              break;
            case this.changePasswordApiCallId:
              this.handleChangePasswordValid(responseJson);
              break;
            default:
          }
        } else {
          return this.navigateToLogin();
        }
      }
    };

    handleRestApiResponse(message)
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getUserProfile()
    this.getSelectedLanguage();
    this.getStudyIndexDetail();
    const studyName = localStorage.getItem('studyName');
    if (studyName) {
      this.setState({ currentStudy: studyName });
    }
  }

  handleDrawerOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  handleDrawerClose = () => {
    this.setState({
      open: false, 
      editProfile: false,
      contactBarOpen: false, 
      studyDrawerOpen: false, 
      fullNameError: '', 
      userEmailError: '', 
      phoneNumberError: '',
      fullName: '', 
      userEmail: '', 
      phoneNumber: '', 
      contactFullName: '',
      contactFullNameError: '',
      contactUserEmail: "",
      contactUserEmailError: "",
      selectedReason: '',
      selectError: '',
      techBarOpen: false,
      profilePicError : false
    });
  };

  handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    this.setState({ expanded: isExpanded ? panel : false });
  };

  handleClickOpen = (name: string) => {
    switch (name) {
        case 'Change Password':
        this.setState({ passwordDialogOpen: true, open: false });
        break;
        case 'Request Admin':
        this.setState({ requestAdmin: true, open: false });
        break;
         case 'Terms and Conditions':
        this.setState({ termsDialogOpen: true, open: false });
        this.getTermsAndCondition();
        break;
        case 'Technical Help':
          this.setState({ techBarOpen: true });
          this.getFaqs();
          break;
        case 'Contact Us':
          this.setState({ contactBarOpen: true });
          this.scrollToTop();
          break;
        case 'Logout':
          this.setState({ logoutFlag: true })
          break;
        case 'Privacy Policies':
        this.setState({ privacyDialogOpen: true, open: false });
        this.getPrivacyPolicy();
        break;
     
      default:
        break;
    }
  };

  handleClose = (name: string) => {
    switch (name) {
      case 'Change Password':
        this.setState({
          passwordDialogOpen: false, open: true,
          oldPassword: "", newPassword: "", newConfirmPassword: "",
          oldPasswordError: '', newPasswordError: '', newConfirmPasswordError: '',
          showOldPassword: false,
          showNewPassword: false,
          showNewConfirmPassword: false,
        });
        break;
        case 'Terms and Conditions':
          this.setState({ termsDialogOpen: false, open: true });
          break;
        case 'Privacy Policies':
          this.setState({ privacyDialogOpen: false, open: true });
          break;
        case 'Technical Help':
          this.setState({ techBarOpen: false, open: true });
          break;
      case 'Request Admin':
        this.setState({
          requestAdmin: false, open: true,
          oldEmailAddress: '', newEmailAddress: '', newConfirmEmailAddress: '',
          oldEmailAddressError: '', newEmailAddressError: '', newConfirmEmailAddressError: '',
          fullNameError: '', userEmailError: '', phoneNumberError: ''
        });
        break;
     
      case 'Contact Us':
        this.setState({
          contactBarOpen: false, open: true, contactFullName: '',
          contactFullNameError: '',
          contactUserEmail: "",
          contactUserEmailError: "",
          selectedReason: '',
          selectError: '',
          contactUserMessage: '',
        });
        break;
      default:
        break;

    }
  }

  handleLogout = () => {
    this.props.navigation.navigate("EmailAccountRegistration")
    if (typeof window !== 'undefined') localStorage.clear();
    
  }

  handleOldPasswordChange = (value: any) => {
    value = value.trim(' ');
    const strongPasswordRegex = configJSON.passwordRegex;
    let oldPasswordError = '';
    if (value !== '') {
      if (value.length > 15) {
        oldPasswordError = 'Password must be at most 15 characters long!';
      } else {
      oldPasswordError = strongPasswordRegex.test(value) ? '' : 'Old password is invalid!';
      }
    }
    this.setState({
      oldPassword: value,
      oldPasswordError: oldPasswordError
    });
  }

  handleNewPasswordChange = (value: any) => {
    value = value.trim(' ');
    const strongPasswordRegex = configJSON.passwordRegex;
    let newPasswordError = '';
    if (value !== '') {
      if (value.length > 15) {
        newPasswordError = 'Password must be at most 15 characters long!';
      } 
      else {
      newPasswordError = strongPasswordRegex.test(value)
        ? ''
        : 'Password must contain 1 uppercase, 1 lowercase, 1 special character, 1 numeric digit, and be 8-15 characters long!';
      }
    }
   
    this.setState({
      newPassword: value,
      newPasswordError: newPasswordError,
      newConfirmPasswordError: value === this.state.newConfirmPassword ? '' : this.state.newConfirmPasswordError,
    });

  }

  handleNewConfirmPasswordChange = (value: any) => {
    this.setState({
      newConfirmPassword: value,
      newConfirmPasswordError: this.state.newPassword === value ? '' : 'Password do not match',
    });
  }
  newSubmitPassWord = async () => {
    const currentPassword = await getStorageData("currentPassword")
    
    if (this.state.oldPassword === "") {
      this.setState({ oldPasswordError: "please enter old password" })
      return
    }
    
    if (this.state.newPassword === "") {
      this.setState({ newPasswordError: "please enter password" })
      return
    }
    
    if (this.state.newConfirmPassword === "") {
      this.setState({ newConfirmPasswordError: "please enter confirm password" })
      return
    }
    
    if (this.state.newPassword !== this.state.newConfirmPassword) {
      this.setState({ newConfirmPasswordError: "Password do not match" })
      return
    }

    if (this.state.newPassword === this.state.oldPassword) {
      this.setState({ newConfirmPasswordError: "Please enter a new password different from the previous one." })
      return
    }

    this.changePassword({ current_password: this.state.oldPassword, new_password: this.state.newPassword })

  }

 

  handleNewEmailAddress = (value: any) => {
    const strongEmailRegex = configJSON.StrongemailRegex;

    this.setState({
      newEmailAddressError: strongEmailRegex.test(value) ? ""  : "Email must be valid!",
      newEmailAddress: value,
    })
  }

  handleOldEmailAddress = (value: any) => {
   
    const strongEmailRegex = configJSON.StrongemailRegex;

    this.setState({
      oldEmailAddressError: strongEmailRegex.test(value) ? ""  : "Old Email is invalid!",
      oldEmailAddress: value,
    })
  }

  handleNewConfirmEmailAddress = (value: any) => {
    this.setState({
      newConfirmEmailAddressError: this.state.newEmailAddress === value 
      ? "" 
      : 'Email does not match!',
      newConfirmEmailAddress: value,
    })
  }

 

  handleFullNameEdit = (event: any) => {
  
    const newFullName = event.target.value;
    const isLengthValid = newFullName.length > 2 && newFullName.length <=50;
    const strongNameRegex = configJSON.nameRegex;

    this.setState({
      fullName: newFullName,
      fullNameError: strongNameRegex.test(newFullName) && isLengthValid
        ? ""
        : 'Please enter a valid full name'
    })
  }

  handleUserNewEmail = (event: any) => {
    const strongEmailRegex = configJSON.StrongemailRegex;
    const newUserEmail = event.target.value;

    this.setState({
      userEmail: newUserEmail,
      userEmailError: strongEmailRegex.test(newUserEmail)
        ? ""
        : "Please enter a valid email"
    })
  }

  newSubmitEmailAddress = () => {
    if (this.state.newEmailAddress === "" && this.state.newConfirmEmailAddress === "" && this.state.oldEmailAddress === "") {
      this.setState({
        newEmailAddressError: 'please enter email',
        newConfirmEmailAddressError: 'please enter confirm email',
        oldEmailAddressError: 'please enter old email'
      })

    } else if (this.state.newEmailAddress === "") {
      this.setState({ newEmailAddressError: 'please enter email' })
    } else if (this.state.newEmailAddress !== this.state.newConfirmEmailAddress) {
      this.setState({ newConfirmEmailAddressError: 'Email does not match!' })
    } else if (this.state.newConfirmEmailAddress === "") {
      this.setState({ newConfirmEmailAddressError: 'please enter confirm email' })
    }
    else {
      this.changeEmail({ current_email: this.state.oldEmailAddress, new_email: this.state.newEmailAddress })

      this.setState({ open: true, requestAdmin: false, oldEmailAddress: '', newEmailAddress: '', newConfirmEmailAddress: '' })
    }
  }

  handlePhoneNumberChange = (event: any) => {
    const strongPhoneNumberRegex = configJSON.phoneNumberRegex;
    const newPhoneNumber = event.target.value;

    this.setState({
      phoneNumber: newPhoneNumber,
      phoneNumberError: strongPhoneNumberRegex.test(newPhoneNumber)
        ? ""
        : 'Please enter a valid phone number'
    });
  };

  handleContactUserEmail = (event: any) => {
    const strongEmailRegex = configJSON.StrongemailRegex;
    const newContactUserEmail = event.target.value;

    this.setState({
      contactUserEmail: newContactUserEmail,
      contactUserEmailError: strongEmailRegex.test(newContactUserEmail)
        ? ""
        : "Please enter a valid email"
    })
  }

  handleContactFullName = (event: any) => {
    const newContactFullName = event.target.value;
    const strongNameRegex = configJSON.nameRegex;
    const isLengthValid = newContactFullName.length > 2;
    
   

    this.setState({
      contactFullName: newContactFullName,
      contactFullNameError: strongNameRegex.test(newContactFullName) && isLengthValid
        ? ""
        : 'Please enter a valid full name'
    })
  }

 

  handleDropdownChange = (event: any) => {
    const newSelectedReason = event.target.value;
    const isError = newSelectedReason === '';

    this.setState({
      selectedReason: newSelectedReason,
      selectError: isError ? "Please select a reason" : ""
    });
  };
  handleUpdateDetailsClick = () => {

    const { fullName, userEmail, phoneNumber, addressLine1, addressLine2, addressLine3, imageToSent } = this.state;
    const isFullNameValid = configJSON.nameRegex.test(fullName) && fullName.length <= 50;
    const isEmailValid = configJSON.StrongemailRegex.test(userEmail);
    const isPhoneNumberValid = configJSON.phoneNumberRegex.test(phoneNumber);

    this.setState({
      fullNameError: isFullNameValid ? "" : 'Please enter a valid full name',
      userEmailError: isEmailValid ? "" : 'Please enter a valid email',
      phoneNumberError: isPhoneNumberValid ? "" : 'Please enter a valid phone number',
    });

    let addressArray = [addressLine1, addressLine2, addressLine3].filter(Boolean); 
    let address = addressArray.join(',');

    if (isFullNameValid && isEmailValid && isPhoneNumberValid ) {
      if(imageToSent){
        this.editUserProfile({
          full_name: fullName,
          full_phone_number: phoneNumber,
          address: address,
          address_line1 : addressLine1,
          address_line2 : addressLine2,
          address_line3 : addressLine3,
          profile_image: this.state.imageToSent 
        })
      }
      else {
        this.editUserProfile({
          full_name: fullName,
          full_phone_number: phoneNumber,
          address: address,
          address_line1 : addressLine1,
          address_line2 : addressLine2,
          address_line3 : addressLine3
        })
      }
    
      this.setState({
        editProfile: false, open: true
      })
    }
  };
  handleContactSubmitButton = () => {
    const { contactFullName, contactUserEmail, selectedReason, contactUserMessage } = this.state
    const isContactUserEmailValid = configJSON.StrongemailRegex.test(contactUserEmail);
    const isContactFullNameValid = configJSON.nameRegex.test(contactFullName) && contactFullName.length > 2;
   
    const isReasonValid = selectedReason !== ''

    this.setState({
      contactFullNameError: isContactFullNameValid ? "" : 'Please enter a valid full name',
      contactUserEmailError: isContactUserEmailValid ? "" : 'Please enter a valid email',
      selectError: isReasonValid ? "" : 'Please select a reason',
    });

    if (isContactFullNameValid && isContactUserEmailValid && selectedReason) {
      this.contactForm({
        name: contactFullName,
        email: contactUserEmail,
        reason: selectedReason,
        message: contactUserMessage
      })
      this.setState({
        contactBarOpen: false, open: true, contactFullName: '', contactUserEmail: '', selectedReason: '', contactUserMessage: ''
      })
    }
  }

  getTermsAndCondition = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTermsandConditionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTermsAndConditionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPrivacyPolicy = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPrivacyPolicyApiCallId = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getNewPrivacyPolicyApiEndPoint
    );

   

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getFaqs = () => {
    let token = typeof window !== 'undefined' ? localStorage.getItem("token") : null
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFaqApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqsApiEndPoint
    );

  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getUserProfile = () => {
    let token = typeof window !== 'undefined' ? localStorage.getItem("token") : null
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserProfileApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserAccountApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  changePassword = ({ current_password, new_password }: { current_password: string, new_password: string }) => {
    let token = typeof window !== 'undefined' ? localStorage.getItem("token") : null
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    }

    const httpBody = {
      data: {
        "current_password": current_password,
        "new_password": new_password
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changePasswordApiEndPoint
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeEmail = ({ current_email, new_email }: { current_email: string, new_email: string }) => {
    let token = typeof window !== 'undefined' ? localStorage.getItem("token") : null
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    }

    const httpBody = {
       
        "old_email": current_email,
        "new_email": new_email
      
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changeEmailApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.changeEmailApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  contactForm = (data: any) => {
    let token = typeof window !== 'undefined' ? localStorage.getItem("token") : null
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const httpBody = {
      "data": {
        "name": data.name,
        "email": data.email,
        "reason": data.reason,
        "message": data.message,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactUsApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

  

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    this.contactFormApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  editUserProfile = (data: any) => {
    let token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;
    const headers = {
      "token": token
    };

    const httpBody = new FormData();
    for (let keys in data) {
      httpBody.append(keys, data[keys])
    }

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      httpBody
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchApiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.editUserProfileApiEnd
    );

    this.editUserProfileApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudyIndexDetail = () => {
    let token = typeof window !== 'undefined' ? localStorage.getItem("token") : null
    const header = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStudyApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    
    this.getStudyIndexApiCallId = requestMessage.messageId;
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }




  handleChangePasswordValid=(responseJson : any)=>{
    this.setState({
      oldPasswordError : responseJson.errors ? responseJson.errors[0].profile : ""
    },() =>{
    });
    
    if(this.state.oldPasswordError == ""){
      this.setState({open:true,showToast:true, oldPassword: "", passwordDialogOpen: false, newPassword: "", newConfirmPassword: "" });
      setTimeout(() => {
        this.setState({
          showToast:false
        });
        this.handleLogout();
      }, 3000);
     }
     else {
      this.setState({ newPassword: this.state.newPassword,oldPassword: this.state.oldPassword,  newConfirmPassword: this.state.newConfirmPassword });
     }
  }

  handleStudyDrawerOpen = () => {
    this.setState({searchQuery : ""});
    this.setState({ studyDrawerOpen: !this.state.studyDrawerOpen });
  };
  handleClickShowOldPassword = () => {
    this.setState((prevState) => ({
      showOldPassword: !prevState.showOldPassword
    }))
   }
   handleClickShowNewPassword = () => {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword
    }))
   }
   handleClickShowNewConfirmPassword = () => {
    this.setState((prevState) => ({
      showNewConfirmPassword: !prevState.showNewConfirmPassword
    }))
   }
   handleProfilePictureChange = (event:any) => {
    this.setState({
      profilePicError : false
    })
    const file = event.target.files[0];
    const acceptedFormats = ['png', 'jpg','jpeg'];
    if(file){
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!acceptedFormats.includes(fileExtension)) {
            this.setState({
              profilePicError : true
            });
            return;
      }
    }

    this.setState({
        profilePictureEdit: URL.createObjectURL(file),
        imageToSent:file
    });
}
getFilteredStudies = () => {
  return this.state.studyData?.filter((item: {
    attributes: any; study_name: string; 
  }) =>
    item.attributes.study_name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
  );
};
handleSearchChange = (event: any) => {
  this.setState({ searchQuery: event.target.value });
};

handleItemClick = (selectedStudy : any) => {
  this.setState((prevState) => ({
    selectedStudy: prevState.selectedStudy === selectedStudy ? null : selectedStudy.id,
    currentStudy : selectedStudy.attributes.study_name,
    studyDrawerOpen : false
  }),()=>{
    localStorage.setItem("studyNumber",selectedStudy.id);
    localStorage.setItem("studyName",selectedStudy.attributes.study_name);
    window.location.reload();
  });
  
};
handleEditLogoClick = ()=>{
  this.setState({ editProfile: true }); 
  this.getUserProfile();
}
handleEditProfileClick = ()=>{
  this.setState({editProfile:false})
}
handlePhoneNumberChangeNew = (phone : string)=>{
    this.setState({
      phoneNumber : phone
    })
}
handleAddressLine1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ addressLine1: event.target.value });
}

handleAddressLine2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ addressLine2: event.target.value });
}

handleAddressLine3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
  this.setState({ addressLine3: event.target.value });
}
handleContactUserMessageChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
 this.setState({ contactUserMessage: event.target.value })
}
handleLogoutFlag=()=>{
  this.setState({ logoutFlag: false })
}
scrollToTop = () => {
  setTimeout(() => {
    if(typeof document !== "undefined"){
      const contactUsHeading = document.getElementById('contact-us-heading');
      if (contactUsHeading) {
          contactUsHeading.scrollIntoView({ behavior: 'smooth' });
      }
    }   
  }, 0); 
};
navigateToSelectStudy=()=>{
    const navigateToLoginMessage2: Message = new Message(
      getName(MessageEnum.handleNavigationMessage)
    );
    navigateToLoginMessage2.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(navigateToLoginMessage2);
}
constructCompleteAddress = (line1: string | null, line2: string | null, line3: string | null): string => {
  return [line1, line2, line3].filter(line => line && line.trim() !== '').join(', ');
}

handleLanguageLogoClick = ()=>{
  this.setState({languageLogoDrawer : !this.state.languageLogoDrawer});
}

getSelectedLanguage = async () => {
  let selLanguage = await getStorageData('lang');
  this.setState({ selectedLanguage: selLanguage });
};

handleLanguageSelect = (language: string) => {
  this.setState({ selectedLanguage: language, languageLogoDrawer: false });
  this.saveSelectedLanguage(language);
};

saveSelectedLanguage = async (language: string) => {
  await setStorageData('lang', language);
  window.location.reload();
};

truncateString(str:string, num:number) {
  if (str.length > num) {
      return str.slice(0, num) + '...';
  } else {
      return str;
  }
}

navigateToLogin = () => {
  if (typeof window !== 'undefined') localStorage.removeItem('token');

  const navigateToLoginMessage: Message = new Message(
    getName(MessageEnum.NavigationEmailLogInMessage)
  );
  navigateToLoginMessage.addData(
    getName(MessageEnum.NavigationPropsMessage),
    this.props
  );
  this.send(navigateToLoginMessage);
}

  // Customizable Area End
}
